// import React from 'react';
// import large from '../assets/big.jpg';
// import small from '../assets/small.jpg';

// const Pataya = () => {
//   return (
//     <div className="flex justify-center items-center h-screen lg:h-auto  ">


// <div className=''>
//       <img 
//         src={small}
        
//         alt="Mobile view" 
//         className="block sm:hidden  w-auto h-auto "
//       />

//       </div>
      
//       <img 
//         src={large}
//         alt="Desktop view" 
//         className="hidden sm:block w-auto  "
//       />
//     </div>
//   );
// };


// export default Pattaya;

import React from 'react';
import large from '../assets/big.jpg';
import small from '../assets/small.jpg';

const Pataya = () => {
  return (
    <div 
      style={{ 
        background: 'linear-gradient(to right, #149cce, #2caf9f)' 
      }} 
      className="flex justify-center items-center h-screen lg:h-auto"
    >
      <div>
        <img 
          src={small}
          alt="Mobile view" 
          className="block sm:hidden w-auto h-auto"
        />
      </div>
      <img 
        src={large}
        alt="Desktop view" 
        className="hidden sm:block w-auto"
      />
    </div>
  );
};

export default Pataya;

